export const calculatePercentDoneTask = (tasks) => {
    const doneTasks = totalDoneTasks(tasks)

    return (doneTasks / tasks.length) * 100
}

export const totalDoneTasks = (tasks) => {
    return tasks.reduce((cur, task) => {
        return cur + (task.is_done ? 1 : 0)
    }, 0)
}
