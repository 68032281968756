import {
    isInteger,
    isEmpty,
    flattenDeep,
    toPairs,
    isArray,
    isObject,
    isNil,
} from 'lodash-es'
import qs from 'qs'
import {
    CURRENCIES,
    DATE_SERVER_FORMAT,
    DOCUMENTS,
    PAYMENT_TERMS,
    SEQUENCE,
    STORAGE,
    USER_ROLES,
} from '@tenant/utils/constants'
import { dayjsFormat, getDate, now } from '@tenant/utils/day'
import cookie from '@tenant/utils/cookie'
import printJS from 'print-js'
import router from '@tenant/core/router'
import BigNumber from 'bignumber.js'
import html2pdf from 'html2pdf.js'
import * as Sentry from '@sentry/vue'

/**
 * @param {string|number} number
 * @param {string} format
 * @returns {string}
 */
export const formatSequence = (number, format) => {
    if (!format || !number) {
        return ''
    }

    return format
        .replaceAll(SEQUENCE.NUMBER, number)
        .replaceAll(SEQUENCE.YYYY, now().format('YYYY'))
        .replaceAll(SEQUENCE.YY, now().format('YY'))
        .replaceAll(SEQUENCE.MM, now().format('MM'))
        .replaceAll(SEQUENCE.M, now().format('M'))
        .replaceAll(SEQUENCE.DD, now().format('DD'))
        .replaceAll(SEQUENCE.D, now().format('D'))
}

const countOccurrences = (str, char) => {
    const regex = new RegExp(char, 'g')
    const matches = str.match(regex)
    return matches ? matches.length : 0
}

const getDecimalPlaces = () => {
    return +localStorage.getItem('currencyDecimalPlaces') || 2
}

const replaceSeparator = (formattedNumber) => {
    const thousandSeparator = localStorage.getItem('currencyThousandSeparator')
    const decimalSeparator = localStorage.getItem('currencyDecimalSeparator')

    return formattedNumber
        .replace(/,/g, '[comma]')
        .replace(/\./g, decimalSeparator)
        .replace(/\[comma\]/g, thousandSeparator)
}

export const setCurrencyFormat = (currency) => {
    const currencyFormat = currency.format
    const decimalPlaces = currency.decimal_places
    const commaCount = countOccurrences(currencyFormat, ',')
    const periodCount = countOccurrences(currencyFormat, '\\.')
    const thousandSeparator = commaCount > periodCount ? ',' : '.'
    const decimalSeparator = commaCount > periodCount ? '.' : ','

    localStorage.setItem('currencyThousandSeparator', thousandSeparator)
    localStorage.setItem('currencyDecimalSeparator', decimalSeparator)
    localStorage.setItem('currencyDecimalPlaces', decimalPlaces)
}

/**
 *
 * @param {Number} val
 * @param {Object} currency
 * @param {Number} decimalPlaces
 * @param {Object} additionalOptions
 * @returns {string}
 */
export const symbolCurrency = (
    val,
    currency,
    decimalPlaces = null,
    additionalOptions = {}
) => {
    decimalPlaces = getDecimalPlaces()
    const decimalOpts = isInteger(decimalPlaces)
        ? {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
          }
        : {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }

    const opts = {
        style: 'currency',
        currency: currency.iso,
        ...decimalOpts,
        ...additionalOptions,
    }

    const formattedAmount = replaceSeparator(
        new Intl.NumberFormat(additionalOptions.locale ?? 'en-US', opts)
            .format(val)
            .replace(currency.symbol, '')
            .trim()
    )

    return `${currency.symbol}${formattedAmount}`
}

/**
 *
 * @param {Number} val
 * @param {String} sign
 * @param {Number} decimalPlaces
 * @param {Object} additionalOptions
 * @returns {string}
 */
export const currency = (
    val,
    sign,
    decimalPlaces = 2,
    additionalOptions = {}
) => {
    decimalPlaces = getDecimalPlaces()
    const decimalOpts = isInteger(decimalPlaces)
        ? {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
          }
        : {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }

    const opts = {
        style: 'currency',
        currency: sign ?? CURRENCIES.eur.iso,
        currencyDisplay: 'code',
        ...decimalOpts,
        ...additionalOptions,
    }

    const formattedAmount = replaceSeparator(
        new Intl.NumberFormat(additionalOptions.locale ?? 'en-US', opts)
            .format(val)
            .replace(sign, '')
            .trim()
    )

    return `${formattedAmount} ${sign}`
}

/**
 *
 * @param {Number} val
 * @param {Number} decimalPlaces
 * @param {Object} additionalOptions
 * @returns {string}
 */
export const numberFormat = (
    val,
    decimalPlaces = null,
    additionalOptions = {}
) => {
    decimalPlaces = getDecimalPlaces()
    const decimalOpts = isInteger(decimalPlaces)
        ? {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
          }
        : {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }

    const opts = {
        ...decimalOpts,
        ...additionalOptions,
    }

    const formattedAmount = replaceSeparator(
        new Intl.NumberFormat(additionalOptions.locale ?? 'en-US', opts)
            .format(val)
            .trim()
    )

    return `${formattedAmount}`
}

export const configApiHeaders = (headers = {}) => {
    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json'
    const centralAccessToken = cookie.getItem(STORAGE.CENTRAL_ACCESS_TOKEN)
    const tenantAccessToken = cookie.getItem(STORAGE.TENANT_ACCESS_TOKEN)
    const tenantId = cookie.getItem(STORAGE.TENANT_ID)
    const socketId = window.Echo?.socketId()

    if (!isEmpty(socketId)) {
        headers['X-Socket-ID'] = socketId
    }

    if (!isEmpty(tenantAccessToken)) {
        headers.Authorization = `Bearer ${tenantAccessToken}`
    }

    if (!isEmpty(centralAccessToken)) {
        headers['Authorization-Central'] = `Bearer ${centralAccessToken}`
    }

    if (!isEmpty(tenantId)) {
        headers.tenant = tenantId
    }

    Sentry.setExtra('tenant_id', tenantId)

    return headers
}

/**
 * Silently update url without triggering route in vue-router
 * @param queries
 */
export const replaceQuery = (queries) => {
    const result = qs.stringify(queries)

    history.replaceState(
        {},
        null,
        router.currentRoute.value.path + '?' + result
    )
}

/**
 * Transfer AWS object to frontend friendly object
 *
 * @param item
 * @returns {{top: *, left: *, width: *, height: *}|null}
 */
export const boundingDTO = (item) => {
    if (!item || !item.geometry || !item.geometry.BoundingBox) {
        return null
    }

    const { Top, Left, Width, Height } = item.geometry.BoundingBox
    return {
        top: Top,
        left: Left,
        width: Width,
        height: Height,
    }
}

/**
 * Convert textract data to vee-validate fields
 *
 * @example
 * // returns [
 *      {
 *          field: "date_of_document",
 *          bounding: {
 *              top: 0.3302910625934601,
 *              left: 0.1270270198583603,
 *              width: 0.24864864349365234,
 *              height: 0.016280217096209526
 *          }
 *      },
 *      {
 *          field: "line_items[0].description",
 *          bounding: {
 *              top: 0.3302910625934601,
 *              left: 0.1270270198583603,
 *              width: 0.24864864349365234,
 *              height: 0.016280217096209526
 *          }
 *      },
 *      {
 *          field: "line_items[0].gross_mount",
 *          bounding: {
 *              top: 0.3302910625934601,
 *              left: 0.1270270198583603,
 *              width: 0.24864864349365234,
 *              height: 0.016280217096209526
 *          }
 *      }
 * ]
 * convertTextractToHighlight({
 *      date_of_document: {
 *          geometry: {
 *              "BoundingBox": {
 *                  "Width": 0.1783783733844757,
 *                  "Height": 0.015293537639081478,
 *                  "Left": 0.569189190864563,
 *                  "Top": 0.6401085257530212
 *              },
 *          }
 *      },
 *      line_items: [
 *          {
 *              description: {
 *                  geometry: {
 *                      "BoundingBox": {
 *                          "Width": 0.1783783733844757,
 *                          "Height": 0.015293537639081478,
 *                          "Left": 0.569189190864563,
 *                          "Top": 0.6401085257530212
 *                      },
 *                  }
 *              },
 *              gross_mount: {
 *                  geometry: {
 *                      "BoundingBox": {
 *                          "Width": 0.1783783733844757,
 *                          "Height": 0.015293537639081478,
 *                          "Left": 0.569189190864563,
 *                          "Top": 0.6401085257530212
 *                      },
 *                  }
 *              },
 *          }
 *      ]
 * });
 *
 * @param object
 * @param keyPrefix
 * @returns {Array<Flat<T>>}
 */
export const convertTextractToHighlight = (object, keyPrefix = '') => {
    const highlights = toPairs(object).map(([fieldKey, fieldValue]) => {
        if (isArray(fieldValue)) {
            return fieldValue.map((item, index) =>
                convertTextractToHighlight(
                    item,
                    `${keyPrefix}${fieldKey}[${index}].`
                )
            )
        } else if (isObject(fieldValue)) {
            return {
                field: `${keyPrefix}${fieldKey}`,
                bounding: boundingDTO(fieldValue),
            }
        } else {
            return {
                field: `${keyPrefix}${fieldKey}`,
            }
        }
    })

    return flattenDeep(highlights)
}

/**
 * Convert textract data to vee-validate fields
 *
 * @example
 * // returns {
 *      date_of_document: '22/02/2022',
 *      line_items: [
 *          {
 *              description: 'Coffee Shop'
 *              gross_mount: 10,
 *              tax_rate: 19
 *          }
 *      ]
 * }
 * convertTextractToFields({
 *      date_of_document: {
 *          value: '22/02/2022',
 *          confident: 100
 *      },
 *      line_items: [
 *          {
 *              description: {
 *                  value: 'Coffee Shop',
 *                  confident: 100
 *              },
 *              gross_mount: {
 *                  value: 10,
 *                  confident: 100
 *              },
 *              tax_rate: {
 *                  value: 19,
 *                  confident: 100
 *              },
 *          }
 *      ]
 * });
 *
 * @param object
 * @returns {Dictionary<any>}
 */
export const convertTextractToFields = (object) => {
    const fields = {}

    toPairs(object).forEach(([fieldKey, fieldValue]) => {
        if (isArray(fieldValue)) {
            fields[fieldKey] = fieldValue.map(convertTextractToFields)
        } else if (isObject(fieldValue)) {
            fields[fieldKey] = fieldValue.value
        } else {
            fields[fieldKey] = fieldValue
        }
    })

    return fields
}

export const getContactName = (item) => {
    if (!item) {
        return null
    }

    if (item?.organisation) {
        return item.organisation.name
    }

    return [item?.person?.first_name, item?.person?.last_name]
        .filter(Boolean)
        .join(' ')
}

export const getContactItemLabel = (item, inputValue) => {
    const regex = new RegExp(inputValue, 'gi')
    const matched = item.label.match(regex)

    if (!matched) {
        return item.label
    }

    return item.label.replace(
        regex,
        (match) => `<span class="font-semibold">${match}</span>`
    )
}

export const getEmployeeName = (item) => {
    if (!item) {
        return null
    }

    return [item?.firstname, item?.last_name].filter(Boolean).join(' ')
}

export const getChartOfAccountName = (item) => {
    if (!item) {
        return null
    }

    return item.name
}

export const getRoleDisplayName = (roles) => {
    if (!roles || roles.length === 0) {
        return ''
    }

    return roles[0].display_name
}

export const getRoleName = (roles) => {
    if (!roles || roles.length === 0) {
        return ''
    }

    return roles[0].name
}

export const getRoleVariant = (roles) => {
    const roleName = getRoleName(roles)

    let variant = 'gray'

    switch (roleName) {
        case USER_ROLES.PRIMARY_ADMIN:
            variant = 'primary'
            break
        case USER_ROLES.ADMIN:
            variant = 'warning'
            break
        default:
            break
    }

    return variant
}

export const downloadPDFFromElement = (elementId, filename) => {
    return html2pdf(document.getElementById(elementId), {
        filename,
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    })
}

export const printContent = (elementId, opts = {}) => {
    // Get HTML to print from element
    const prtHtml = document.getElementById(elementId).innerHTML

    // Apply styles to the print window
    let configStyles = ''
    const { orientation } = opts
    if (orientation) {
        configStyles += `@page { size: ${orientation}; }`
    }

    // Get all stylesheets HTML
    let stylesHtml = ''
    for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
    ]) {
        stylesHtml += node.outerHTML
    }

    // Open the print window
    const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
    )

    WinPrint.document.write(`<!DOCTYPE html>
<html lang="en">
  <head>
    ${stylesHtml}
    <style>
    ${configStyles}
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }
    </style>
    <title>${document.title}</title>
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

    WinPrint.document.close()
    WinPrint.focus()
    WinPrint.onload = function () {
        WinPrint.print()

        WinPrint.onfocus = function () {
            setTimeout(function () {
                WinPrint.close()
            }, 100)
        }
    }
}

export const isDocumentImageFile = (document) => {
    const fileType = document?.mime
    if (!fileType) {
        return false
    }

    return (
        fileType.includes('jpeg') ||
        fileType.includes('jpg') ||
        fileType.includes('gif') ||
        fileType.includes('png')
    )
}

export const isDocumentPdfFile = (document) => {
    const fileType = document?.mime
    if (!fileType) {
        return false
    }

    return fileType === 'application/pdf' || fileType.includes('pdf')
}

export const printDocument = (content) => {
    printJS({
        printable: content,
        type: 'pdf',
        base64: true,
    })
}

export const downloadFileFromUrl = (url, filename) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
}

export const exportPDF = (fileName, content) => {
    const linkSource = `data:application/pdf;base64,${content}`
    downloadFileFromUrl(linkSource, fileName)
}

/**
 * Get payment date from payment term and base date
 * @param date
 * @param term
 * @returns {null|string}
 */
export const dateFromPaymentTerm = (date, term) => {
    const baseDate = unref(date)
    const paymentTerm = unref(term)
    let paymentDate = null

    if (!baseDate) return null

    if (paymentTerm === PAYMENT_TERMS.DUE_ON_RECEIPT) {
        paymentDate = baseDate
    }

    if (paymentTerm === PAYMENT_TERMS.NET15) {
        paymentDate = getDate(baseDate)
            .add(15, 'day')
            .format(DATE_SERVER_FORMAT)
    }

    if (paymentTerm === PAYMENT_TERMS.NET30) {
        paymentDate = getDate(baseDate)
            .add(30, 'day')
            .format(DATE_SERVER_FORMAT)
    }

    if (paymentTerm === PAYMENT_TERMS.NET45) {
        paymentDate = getDate(baseDate)
            .add(45, 'day')
            .format(DATE_SERVER_FORMAT)
    }

    if (paymentTerm === PAYMENT_TERMS.END_OF_MONTH) {
        paymentDate = getDate(baseDate)
            .endOf('month')
            .format(DATE_SERVER_FORMAT)
    }

    if (paymentTerm === PAYMENT_TERMS.DUE_NEXT_MONTH) {
        paymentDate = getDate(baseDate)
            .add(1, 'month')
            .endOf('month')
            .format(DATE_SERVER_FORMAT)
    }

    return paymentDate
}

export const getReceiverFullName = (participants, currentUser) => {
    const receiver = getReceiver(participants, currentUser)
    if (!receiver) {
        return null
    }

    return getUserDisplayName(receiver)
}

export const getReceiver = (participants, currentUser) => {
    const participant = participants.find((p) => p.user.id !== currentUser.id)

    if (!participant) {
        return null
    }

    return participant.user
}

export const getUserDisplayName = (user) => {
    return user?.full_name || user?.email
}

export const getClientDisplayName = (client) => {
    return (
        client?.display_name ||
        client?.company_name ||
        client?.full_name ||
        client?.email
    )
}

export const isDifferenceDayMessage = (message1, message2) => {
    if (!message1 || !message2) {
        return false
    }

    if (message1.diff_date.day !== message2.diff_date.day) {
        return true
    }

    const day1 = dayjsFormat(message1.created_at, 'D')
    const day2 = dayjsFormat(message2.created_at, 'D')

    return day1 !== day2
}

export const getShortNumber = (val) => {
    const min = 1e6
    const absVal = Math.abs(val)
    if (absVal >= min) {
        const signal = val < 0 ? -1 : 1
        const units = ['M', 'B', 'T']

        const order = Math.floor(Math.log(absVal) / Math.log(1000))

        const unit = units[order - 2]
        const num = new BigNumber((absVal / 1000 ** order) * signal).toFormat(
            2,
            1
        )

        return `${num}${unit}`
    }

    return new BigNumber(val).toFormat(0)
}

export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

export const increaseBrightness = (hex, percent) => {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '')

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1')
    }

    const r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16)

    return (
        '#' +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
            .toString(16)
            .substring(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
            .toString(16)
            .substring(1)
    )
}

export const scrollParentToChild = (parent, child) => {
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect()
    // What can you see?
    const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
    }

    // Where is the child
    const childRect = child.getBoundingClientRect()
    // Is the child viewable?
    const isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        const scrollTop = childRect.top - parentRect.top
        const scrollBot = childRect.bottom - parentRect.bottom
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
            // we're near the top of the list
            parent.scrollTop += scrollTop
        } else {
            // we're near the bottom of the list
            parent.scrollTop += scrollBot
        }
    }
}

export const isFolder = (item) => item.type === DOCUMENTS.FOLDER

export const iconFileName = (item) => {
    let iconName = 'line-icons:files:file-04'
    switch (item.mime) {
        case 'png':
        case 'jpg':
        case 'jpeg':
            iconName = 'line-icons:images:image-01'
            break
        case 'mp4':
            iconName = 'line-icons:media-&-devices:film-02'
            break
        case 'fig':
            iconName = 'line-icons:editor:figma'
            break
        case 'framer':
            iconName = 'line-icons:editor:framer'
            break
        default:
            break
    }

    return iconName
}

export const empty = (value) => {
    if (typeof value === 'string') {
        return value === ''
    }

    if (typeof value === 'number') {
        return value === 0 || value === 0.0
    }

    return isNil(value) || isNaN(value)
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const sameOrigin = (a, b) => {
    const urlA = new URL(a)
    const urlB = new URL(b)
    return urlA.origin === urlB.origin
}
